import { CancelToken } from "axios";

import getDispensariesV3 from "api/requests/consumerApi/getDispensariesV3";
import finderApi from "api/services/finder-api";
import { SortParam } from "custom-types/AvailableFilters";
import { RetailType, StoresResponse } from "custom-types/Store";
import { finderNoResults } from "utils/finder/finderResultUtils";
import { BoundingBox } from "utils/finder/mapUtils";
import { hasValidRequestBoundingBox } from "utils/hasValidBoundingBox";
import hydrateStores from "utils/hydrateStores";
import logError from "utils/logError";

export type GeoQueryType = "point" | "bounding-box" | "intersects";

export interface GetFinderDispensariesQueryParams {
  boundingBox?: BoundingBox;
  city?: string;
  countryCode?: string;
  filter?: string[];
  flags?: string[];
  geo_query_type?: GeoQueryType;
  ids?: number[];
  include_spotlight?: boolean;
  isLocationPage?: boolean;
  lat: number;
  limit?: number;
  lon: number;
  merch_unit_name?: string;
  newDefaultSort?: string;
  newStoreExcludeIds?: number[];
  organization_id?: number;
  page?: number;
  platinum_take?: number;
  product_type?: string;
  promoteNewStores?: boolean;
  radius?: number | string | null;
  retailType?: RetailType;
  return_facets?: boolean;
  sort?: SortParam;
  state?: string;
  strain?: string;
  zip?: string;
}

// Northwest Longitude, Southeast Latitude, Southeast Longitude, Northwest Latitude
export const formatFinderServiceBoundingBox = (boundingBox: BoundingBox) =>
  `${boundingBox.nw.lng},${boundingBox.se.lat},${boundingBox.se.lng},${boundingBox.nw.lat}`;

export default async function getDispensaries(
  query: GetFinderDispensariesQueryParams,
  opts: { cancelToken?: CancelToken; useConsumerAPIFlagEnabled?: boolean } = {},
) {
  try {
    const {
      lat,
      lon,
      filter = [],
      countryCode = "US",
      retailType = "dispensary",
      geo_query_type = "point",
      sort = "default",
      page = 1,
      limit = 50,
      strain = null,
      product_type = null,
      radius: queryRadius = null,
      boundingBox = null,
      newDefaultSort = "default",
      ids = null,
      promoteNewStores,
      newStoreExcludeIds,
      organization_id = null,
      merch_unit_name,
      isLocationPage,
      flags,
      include_spotlight = false,
    } = query;

    if (!lat || !lon) {
      throw new Error(`lat and lon required are required parameters!
    lat: ${lat}
    lon: ${lon}`);
    }

    if (boundingBox && !hasValidRequestBoundingBox(boundingBox)) {
      throw new Error("Invalid bounding box");
    }

    if (geo_query_type === "point" && !ids && !queryRadius) {
      throw new Error(
        `Radius is required for geo_query_type: point! \`radius\`: ${queryRadius}`,
      );
    }

    // TODO: require consistency across finder by requiring this be a string in miles (e.g. "5mi"). We'll throw an error if it doesn't meet this requirement.
    const radius = queryRadius
      ? typeof queryRadius === "number"
        ? `${queryRadius}mi`
        : queryRadius
      : null;

    const params = {
      filter: [...filter, retailType],
      geo_query_type,
      ...(ids && { ids }),
      ...(promoteNewStores && { promote_new_stores: promoteNewStores }),
      ...(newStoreExcludeIds && { new_store_excluded_ids: newStoreExcludeIds }),
      lat,
      limit,
      lon,
      ...(organization_id && { organization_id }),
      page,
      ...(radius && !boundingBox && { radius }),
      return_facets: true,
      sort,
      sort_version: newDefaultSort,
      ...(strain && { strain: encodeURI(strain) }),
      ...(product_type && { product_type: encodeURI(product_type) }),
      ...(geo_query_type === "bounding-box" &&
        boundingBox && {
          bounding_box: formatFinderServiceBoundingBox(boundingBox),
        }),
      ...(merch_unit_name && { merch_unit_name: encodeURI(merch_unit_name) }),
    };

    const v3Params = {
      geo_query_type,
      lat,
      lon,

      skip: (page - 1) * limit,
      sort,
      take: limit,

      ...(ids && { ids }),

      ...(radius && !boundingBox && { radius }),
      ...(geo_query_type === "bounding-box" &&
        boundingBox && {
          bottomLat: boundingBox.se.lat,
          leftLon: boundingBox.nw.lng,
          rightLon: boundingBox.sw.lng,
          topLat: boundingBox.ne.lat,
        }),

      ...(strain && { strainName: [encodeURI(strain)] }),
      ...(product_type && { productCategory: [encodeURI(product_type)] }),
      ...(flags && { flags }),
      ...(include_spotlight && { include_spotlight }),
    };

    let dispensariesResult;

    const useConsumerAPIV3GetDispensaries =
      opts.useConsumerAPIFlagEnabled &&
      sort === "default" &&
      filter.length === 0;
    if (useConsumerAPIV3GetDispensaries) {
      const consumerApiResult = await getDispensariesV3(v3Params);
      dispensariesResult = consumerApiResult;
    } else {
      const finderResult = await finderApi.get("/v3/dispensaries", {
        cancelToken: opts.cancelToken,
        headers: { "X-Country-Code": countryCode },
        params,
      });
      dispensariesResult = finderResult.data;
    }

    const useFallbackRadiusRequest =
      !isLocationPage &&
      dispensariesResult.stores.length === 0 &&
      dispensariesResult.sponsoredStores.length === 0 &&
      !organization_id &&
      radius &&
      !ids &&
      geo_query_type === "point" &&
      !merch_unit_name;

    if (useFallbackRadiusRequest) {
      const radiusAsNumber = params.radius
        ? Number(params.radius.slice(0, -2))
        : null;
      if (!radiusAsNumber || radiusAsNumber < 150) {
        if (useConsumerAPIV3GetDispensaries) {
          v3Params.geo_query_type = "point";
          v3Params.radius = "150mi";
          const consumerApiResult = await getDispensariesV3(v3Params);
          dispensariesResult = consumerApiResult;
        } else {
          params.geo_query_type = "point";
          params.radius = "150mi";
          const { data } = await finderApi.get("/v3/dispensaries", {
            headers: { "X-Country-Code": countryCode },
            params: { ...params },
          });
          dispensariesResult = data;
        }
      }
    }

    const {
      stores = [],
      sponsoredStores = [],
      spotlight = [],
      promotedNewStores = [],
    } = dispensariesResult;

    return {
      ...dispensariesResult,
      promotedNewStores,
      sponsoredStores: hydrateStores(sponsoredStores),
      spotlight: hydrateStores(spotlight),
      stores: hydrateStores(stores),
      ...(radius && !useFallbackRadiusRequest && { radius }),
      ...(useFallbackRadiusRequest && { radius: "150mi" }),
    } as StoresResponse;
  } catch (e) {
    if (e.statusCode === "ERR_CANCELED") {
      return {
        ...finderNoResults,
        cancelled: true,
      };
    }

    logError(e.message, {
      functionName: "getFinderDispensaries",
      service: "finder",
      statusCode: e.statusCode,
    });

    return finderNoResults;
  }
}
